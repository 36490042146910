import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";



import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import "./assets/css/style.css";
import Main from './components/Website/Main';
import Landing from './components/Website/Landing';
import Whyuse from './components/Website/Whyuse';


const Index = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Main />} >
            <Route path="" element={<Landing />} />
            <Route path="/" element={<Landing />} />
            <Route path="/home" element={<Landing />} />
            <Route path="/why-bussinessi.ai" element={<Whyuse />} />
          
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);