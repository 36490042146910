import React, { useState, useEffect } from 'react';

const TypingTextSequence = ({ texts, typingSpeed = 100, deletingSpeed = 50, delay = 1500 }) => {
    const [currentText, setCurrentText] = useState(''); // Holds the current text being displayed
    const [isDeleting, setIsDeleting] = useState(false); // Whether the text is being deleted
    const [currentIndex, setCurrentIndex] = useState(0); // Index of the current text
    const [charIndex, setCharIndex] = useState(0); // Index of the current character
    const [isBlinking, setIsBlinking] = useState(false); // Controls cursor blinking

    const textArray = texts; // Array of texts to display

    useEffect(() => {
        let timer;

        if (!isDeleting && charIndex < textArray[currentIndex].length) {
            // Typing text
            timer = setTimeout(() => {
                setCurrentText((prev) => prev + textArray[currentIndex][charIndex]);
                setCharIndex(charIndex + 1);
            }, typingSpeed);
            setIsBlinking(false); // No blinking during typing
        } else if (isDeleting && charIndex > 0) {
            // Deleting text
            timer = setTimeout(() => {
                setCurrentText((prev) => prev.slice(0, -1));
                setCharIndex(charIndex - 1);
            }, deletingSpeed);
            setIsBlinking(false); // No blinking during deletion
        } else if (!isDeleting && charIndex === textArray[currentIndex].length) {
            // Once the sentence is fully typed, start blinking and wait before deleting
            setIsBlinking(true);
            timer = setTimeout(() => setIsDeleting(true), delay);
        } else if (isDeleting && charIndex === 0) {
            // Once the sentence is fully deleted, move to the next sentence and blink
            setIsBlinking(true);
            setIsDeleting(false);
            setCurrentIndex((prev) => (prev + 1) % textArray.length); // Cycle through the texts
        }

        return () => clearTimeout(timer);
    }, [charIndex, isDeleting, textArray, currentIndex, typingSpeed, deletingSpeed, delay]);

    // Inline styles for blinking cursor
    const cursorStyle = {
        display: 'inline-block',
        fontWeight: 'bold',
        animation: isBlinking ? 'blink 1s step-end infinite' : 'none', // Blink only when typing or deleting completes
    };



    return (
        <>
            {currentText}
            <span style={cursorStyle}>|  <i style={{ opacity: '0' }}></i></span>
        </>
    );
};

export default TypingTextSequence;
