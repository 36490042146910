import React, { useState, useEffect } from "react";

import Coin from '../../assets/images/coin.svg'
import mobile from '../../assets/images/landing/moible.png'


import features_img02 from '../../assets/images/landing/features_img02.png'
import features_img03 from '../../assets/images/landing/features_img03.png'
import features_img04 from '../../assets/images/landing/features_img04.png'
import download_btn01 from '../../assets/images/landing/download_btn01.png'
import download_btn02 from '../../assets/images/landing/download_btn02.png'

import download_img02 from '../../assets/images/landing/download_img02.png'
import download_shape01 from '../../assets/images/landing/download_shape01.png'
import download_shape02 from '../../assets/images/landing/download_shape02.png'

import Animation from "./Animation";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import boy from "../../assets/images/avatar/1.png";
import user from "../../assets/images/avatar/6.png";
import girl from "../../assets/images/avatar/3.png";
import PartnerSlider from "./sections/PartnersSlider";
import TypingText from "./components/TypingText";
import Home from "./Home";

function Landing(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const featureData = [
    {
      title: 'Mobile Payment Make Easy',
      description: 'Add new, trending and rare artwork to your collection.',
      imgSrc: Coin,
      link: '/#!',
    },
    {
      title: 'Lifetime Free Transaction',
      description: 'Add new, trending and rare artwork to your collection.',
      imgSrc: features_img02,
      link: '/#!',
    },
    {
      title: 'Protect the Identity',
      description: 'Add new, trending and rare artwork to your collection.',
      imgSrc: features_img03,
      link: '/#!',
    },
    {
      title: 'Security & Control Over Money',
      description: 'Add new, trending and rare artwork to your collection.',
      imgSrc: features_img04,
      link: '/#!',
    },
  ];

  const faqItems = [
    {
      question: "What is Bussiness.ai Coin?",
      answer: "Bussiness.ai Coin is a digital currency backed by physical diamonds and land worth the price of every individual coin.",
    },
    {
      question: "How Bussiness.ai Coin can be used?",
      answer: "IYou can use DLC just like any other cryptocurrency in the world. Use it for purchases and transfers over the blockchain network.",
    },
    {
      question: "How to earn with Bussiness.ai Coin?",
      answer: "YDLC can be traded on all leading exchanges as a crypto asset and you can thus earn from the fluctuation in its price over time.",
    }

  ];
  const testimonials = [
    {
      stars: ["star", "star", "star", "star_half"],
      detail: `"Bussinessi.ai has been instrumental in helping me navigate the complex world of investments. Their personalized approach and transparent communication make them stand out. I've witnessed steady growth in my portfolio, all thanks to Bussinessi.ai guidance and expertise."`,
      user: {
        name: "Jack",
        job: "Trader",
        image: boy
      }
    },
    {
      stars: ["star", "star", "star", "star_half"],
      detail: `"Bussinessi.ai has been instrumental in helping me navigate the complex world of investments. Their personalized approach and transparent communication make them stand out. I've witnessed steady growth in my portfolio, all thanks to Bussinessi.ai guidance and expertise."`,
      user: {
        name: "Jerrell Weatherby",
        job: "Trader",
        image: user
      }
    },
    {
      stars: ["star", "star", "star", "star_half"],
      detail: `"I'm truly impressed with Bussinessi.ai commitment to their clients. Their tailored investment strategies have allowed me to achieve my financial goals faster than I ever thought possible. If you're serious about securing your financial future, I wholeheartedly recommend Bussinessi.ai."`,
      user: {
        name: "Jani",
        job: "Trader",
        image: girl
      }
    }
  ];
  const blogs = [
    {
      image: "https://www.diamondlandcoin.com/assets/images/blog1.jpg",
      date: "21 July 2022",
      title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      link: "#"
    },
    {
      image: "https://www.diamondlandcoin.com/assets/images/blog2.jpg",
      date: "21 July 2022",
      title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      link: "#"
    },
    {
      image: "https://www.diamondlandcoin.com/assets/images/blog3.jpg",
      date: "21 July 2022",
      title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      link: "#"
    }
  ];


  return (
    <>

      <Animation />
      <Home />
      <section className="features-area simple-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="section-title text-center mb-70">
                <h2 className="title">Revolutionary ICO Platform with Exclusive Rewards Program</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {featureData.map((feature, index) => (
              <div className="col-md-6" key={index}>
                <div className="features-item">
                  <div className="features-content">
                    <h2 className="title">
                      <a href={feature.link}>{feature.title}</a>
                    </h2>
                    <p>{feature.description}</p>
                  </div>
                  <div className="features-img">
                    <img src={feature.imgSrc} alt={feature.title} />
                  </div>
                </div>

              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="simple-section">
        <div className="container">
          <div className='section-title mb-5 text-center'>
            <h4 className='title'>Partners</h4>
          </div>
          <PartnerSlider />
        </div>
      </div >
      <section className="download-area simple-section">
        <div className="container">
          <div className="download-inner-wrap">
            <div className="row align-items-end">
              <div className="col-md-6">
                <div className="download-content">
                  <div className="section-title mb-4">
                    <h2 className="title">Control Application From Your Mobile</h2>
                  </div>
                  <div className="download-list">
                    <ul className="list-wrap">
                      <li><span>1</span>White Paper</li>
                      <li><span>2</span>Privacy & Policy</li>
                    </ul>
                  </div>
                  <div className="download-btn-wrap">
                    <Link className="download-btn" to="/">
                      <img className="img-fluid" src={download_btn01} alt="Download Button 1" />
                    </Link>
                    <Link className="download-btn" to="/">
                      <img className="img-fluid" src={download_btn02} alt="Download Button 2" />
                    </Link>
                  </div>
                </div>
              </div>

              {/* Download Images */}
              <div className="col-md-6">
                <div className="download-img">
                  <img className="img-fluid" src={mobile} alt="Download Image 1" />
                  <img className="img-fluid" src={download_img02} alt="Download Image 2" />
                </div>
              </div>
            </div>

            {/* Decorative Shapes */}
            <div className="download-shape-wrap">
              <img src={download_shape01} alt="Download Shape 1" />
              <img src={download_shape02} alt="Download Shape 2" />
            </div>
          </div>
        </div>
      </section>
      <div className="simple-section bg-gray">
        <div className="tg-container">
          <div className="experience">
            <div className="section-title text-center">
              <div className='title'>
                CRYPTOCURRENCY <span>PRICES</span>
              </div>
            </div>
            <div className="section-detail text-center">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. <br />
              It has survived not only five centuries.
            </div>
            <div
              className='row mt-5'
            >
              <div className="col-md-12">
                <div class="cryptohopper-web-widget" data-id="1" data-chart_color="#FFBE18" data-table_title=""
                  data-table_style="dark"
                  data-table_columns="name,price_usd,volume_usd_24h,available_supply,percent_change_24h" data-numcoins="10"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="faq-area">
        <div className="container">
          <div className="faq-inner-wrap">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center mb-4">
                  <h2 className="title">Ask Quick Question</h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="faq-wrap">
                  <div className="accordion" id="accordionExample">
                    {faqItems.map((item, index) => (
                      <div key={index} className={`accordion-item ${activeIndex === index ? 'active' : ''}`}>
                        <h2 className="accordion-header">
                          <button
                            className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                            type="button"
                            onClick={() => toggleAccordion(index)}
                          >
                            {item.question}
                          </button>
                        </h2>
                        <div className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}>
                          <div className="accordion-body">
                            <p>{item.answer}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="simple-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center section-title">
              <p>BLOG</p>
              <h1 className="title">Read Our News</h1>
            </div>
          </div>

          <div className="row">
            {blogs.map((blog, index) => (
              <div key={index} className="col-md-4">
                <div className="blog">
                  <img src={blog.image} alt={blog.title} />
                  <div className="content">
                    <p className="date">{blog.date}</p>
                    <h3 className="title">{blog.title}</h3>
                    <a href={blog.link} className="link">Read More</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>



      </section>
      <section className="simple-section people-say">
        <div className="tg-container">

          <div className="section-title text-center mb-5">
            <div className='title'>
              WHAT OUR CLIENTS SAY <span>
                <TypingText
                  texts={[
                    'Bussinessi.ai'
                  ]}
                  typingSpeed={150} deletingSpeed={80} delay={1000} /></span>
            </div>
          </div>


          <div className="row">
            <div className="col-md-10 offset-md-1">
              <Carousel indicators={false}>
                {testimonials.map((testimonial, index) => (
                  <Carousel.Item key={index}>
                    <div className="rating-card">
                      <div className="starts">
                        {testimonial.stars.map((star, i) => (
                          <span key={i} className="material-symbols-outlined">
                            {star}
                          </span>
                        ))}
                      </div>
                      <p className="section-detail">{testimonial.detail}</p>
                      <div className="profile">
                        <div className="user-detail">
                          <div className="user_name">{testimonial.user.name}</div>
                          <div className="job">{testimonial.user.job}</div>
                        </div>
                        <div className="user">
                          <img src={testimonial.user.image} alt={testimonial.user.name} />
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>


            </div>
          </div>
        </div>
      </section >
      <section className="document-area">
        <div className="container">
          <div className="document-inner-wrap">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center mb-5">
                  <h2 className="title">Have Any Questions?</h2>
                </div>
              </div>
            </div>

            <div className="row">
              {/* Contact Form */}
              <div className="col-md-8 offset-md-2">
                <div className="document-form-wrap">
                  <h4 className="title">Get In Touch Now</h4>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input type="text" name="name" placeholder="Your Name" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input type="email" name="email" placeholder="Your Email" />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea name="message" placeholder="Enter your message..."></textarea>
                    </div>
                    <button type="submit" className="button primary">Send Message</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default Landing;



