import React, { useEffect, useState } from 'react';
import logo from '../../../assets/images/coin.svg';
import { Link, NavLink } from 'react-router-dom';

function Header() {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = 150;
            setIsScrolled(window.scrollY > scrollThreshold);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>

            <header id="header" className={isScrolled ? 'sticky' : ''}>
                <nav className="navbar navbar-expand-lg bg-transparant">
                    <div className="container">
                        <NavLink className="navbar-brand" to="#/">
                            <img src={logo} alt="Logo" className="brand" />
                        </NavLink>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <NavLink className="nav-link active" to="/">Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/why-bussinessi.ai">Why Bussinessi.ai?</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="#/">How it works</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="#/">News</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="#/">Whitepaper</Link>
                                </li>
                            </ul>

                        </div>
                        <div className="header-action">
                            <ul className="list-wrap">
                                <li className="header-login">
                                    <a target='_blank' href="https://businessi.ai/" className='button primary'>Login
                                        <span className="material-symbols-outlined">
                                            person
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <div className='navbar-toggler' data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="material-symbols-outlined open">menu</span>
                                        <span class="material-symbols-outlined close">close</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

        </>
    );
}

export default Header;
