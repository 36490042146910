
import React from 'react';
import logo from '../../../assets/images/coin.svg';
import footer_bg from '../../../assets/images/footer/footer_bg.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <div className="footer-area footer-bg" style={{ backgroundImage: `url(${footer_bg})` }}>
                <div className="container">
                    <div className="footer-top">
                        <div className="row align-items-center">

                            <div className="col-md-2">
                                <div className="footer-widget">
                                    <div className="footer-link">
                                        <img src={logo} alt="Logo" className="brand img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-7">
                                <div className="footer-widget">
                                    <h4 className="fw-title">About Us</h4>
                                    <div className="footer-link">
                                        <ul className="list-wrap">
                                            <li>
                                                <Link to="#/">
                                                    The Bussinessi.ai Coin team aims to create an innovative and intuitive cryptocurrency trading platform with superior customer service. Our goal is to construct a trustworthy, secure and convenient environment for the Bussinessi.ai and land industry.
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-3">
                                <div className="footer-widget">
                                    <h4 className="fw-title">Subscribe Newsletter</h4>
                                    <div className="footer-newsletter">
                                        <p>Exerci tation ullamcorper suscipit lobortis nisl aliquip ex ea commodo</p>
                                        <form>
                                            <div className="form-group">
                                                <input type="email" name="email" placeholder="Info@gmail.com" />
                                                <button type="submit">
                                                    <span class="material-symbols-outlined">
                                                        send
                                                    </span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="footer-bottom">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright-text">
                                    <p>Copyright © 2024 Bussinessi.ai. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

