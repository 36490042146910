import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './include/Header'
import Footer from './include/Footer'

const Main = () => {


    useEffect(() => {
        const closeNavbar = () => {
            var Navbar = document.getElementById("navbarSupportedContent");
            if (Navbar.classList.contains('show')) {
                Navbar.classList.remove('show');
            }
        };

        document.addEventListener('click', closeNavbar);

        return () => {
            document.removeEventListener('click', closeNavbar);
        };
    }, []);
   

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
          
        </>
    )
}

export default Main