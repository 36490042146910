import React from 'react'
import banner_bg from '../../assets/images/landing/banner_bg.png'
import banner_shape01 from '../../assets/images/landing/banner_shape01.png'
import banner_shape02 from '../../assets/images/landing/banner_shape02.png'
import TypingText from "./components/TypingText";
import Coin from '../../assets/images/coin.svg'


const Home = () => {

    return (
        <>


            <section
                className="banner"
                style={{ backgroundImage: `url(${banner_bg})` }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 text-center">
                            <img src={Coin} alt="" className="mb-4" />
                            <div className="banner-content text-center">
                                <h2 className="title">
                                    Bussinessi.ai <br />
                                    <span>

                                        <TypingText
                                            texts={[
                                                'Driving The Future Of Money',
                                                'Exponentional Growth',
                                                'Unparallel Liquidity'
                                            ]}
                                            typingSpeed={150} deletingSpeed={80} delay={1000} />
                                    </span>
                                </h2>
                                <p>
                                    A highly scalable, fast and secure blockchain platform for distributed apps enterprise use cases and the new internet economy.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-scroll-down">
                    <a href="#contribution" className="section-link">
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </div>
                <div className="banner-shape-wrap">
                    <img
                        src={banner_shape01}
                        alt=""
                        className="leftToRight"
                    />
                    <img
                        src={banner_shape02}
                        alt=""
                        className="alltuchtopdown"
                    />
                </div>
            </section>
        </>
    )
}

export default Home