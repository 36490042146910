import React from 'react'
import Coin from '../../assets/images/coin.svg'
import block from '../../assets/images/why/block.png'
import high_liqudity from '../../assets/images/why/high_liqudity.png'
import large_scale from '../../assets/images/why/large_scale.png'
import fully_transperent from '../../assets/images/why/fully_transperent.png'
import stability from '../../assets/images/why/stability.png'
const Whyuse = () => {


    const whyData = [
        {
            id: 1,
            title: "MULTIPLE BLOCKCHAINS SUPPORT",
            description:
                "DLC is supported on leading crypto-trading platforms and incorporates multiple blockchain support. Driven by the idea of creating a highly functional crypto-mining ecosystem, DLC has developed its own DApp/wallet that allows for easy mobility of transactions.",
            imgSrc: block,
        },
        {
            id: 2,
            title: "HIGH LIQUIDITY",
            description:
                "DLC is a widely traded coin/token and thus, offers high liquidity.",
            imgSrc: high_liqudity,
        },
        {
            id: 3,
            title: "LARGE SCALE ADOPTION",
            description:
                "DLC has been adopted by the public and is actively being used for day-to-day transactions with payment gateways.",
            imgSrc: large_scale,
        },
        {
            id: 4,
            title: "100% BACKED BY DLC'S RESERVES",
            description:
                "DLC is a widely traded coin/token and thus, offers high liquidity.",
            imgSrc: "",
        },
        {
            id: 5,
            title: "FULLY TRANSPARENT",
            description:
                "DLC has made all token issues and reserves public with data that is constantly updated.",
            imgSrc: fully_transperent,
        },
        {
            id: 6,
            title: "HIGH STABILITY",
            description:
                "Bussinessi.ai is a blockchain-based ERC20 token. DLC provides faster transaction speed, better stability, and cross-chain compatibility to users.",
            imgSrc: stability,
        },
    ];

    return (
        <>
            <section className="simple-section why-page">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-9">
                            <div className="section-title">
                                <div className="title">
                                    Why use Bussinessi.ai?
                                </div>
                            </div>
                            <div className="section-detail">
                                Launched in 2014, Bussinessi.ai
                                pioneered the stablecoin model and are the most widely traded.
                                Tether tokens offer the stability and simplicity of fiat c
                                urrencies coupled with the innovative nature of blockchain
                                technology, representing a perfect combination of both worlds.
                            </div>
                        </div>
                        <div className="col-md-3">
                            <img src={Coin} alt="Coin" className='img-fluid w-100' />
                        </div>
                    </div>
                    <div className="row gy-4 mt-4 align-items-center">
                        <div className="col-md-6">
                            <h1>
                                <strong className='text-primary'>Bussinessi.ai </strong>
                                Whether it is for personal use
                                or for business purposes,
                            </h1>
                        </div>
                        <div className="col-md-6">
                            <h1>
                                <strong className='text-primary'>Bussinessi.ai</strong> tokens offer many benefits as the most stable, liquid and trusted stable coin.
                            </h1>
                        </div>

                        {whyData.map((item) => (
                            <div className="col-md-6 mt-5" key={item.id}>
                                <div className="why-card">
                                    <h3 className="bold highlight">{item.title}</h3>
                                    <br />
                                    <p className="">{item.description}</p>
                                    <img className="why-img" src={item.imgSrc} alt={item.title} />
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
            </section>
        </>
    )
}

export default Whyuse