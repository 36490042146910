import React from 'react';

import amazone from "../../../assets/images/landing/partners/amazon.svg";
import apple from "../../../assets/images/landing/partners/apple.svg";
import atandt from "../../../assets/images/landing/partners/atandt.svg";
import cocacola from "../../../assets/images/landing/partners/cocacola.svg";
import google from "../../../assets/images/landing/partners/google.svg";
import intel from "../../../assets/images/landing/partners/intel.svg";
import kfc from "../../../assets/images/landing/partners/kfc.svg";
import linkedin from "../../../assets/images/landing/partners/linkedin.svg";
import mcdonald from "../../../assets/images/landing/partners/mcdonald.svg";
import microsoft from "../../../assets/images/landing/partners/microsoft.svg";
import nike from "../../../assets/images/landing/partners/nike.svg";
import pepsi from "../../../assets/images/landing/partners/pepsi.svg";
import pg from "../../../assets/images/landing/partners/pg.svg";
import samsung from "../../../assets/images/landing/partners/samsung.svg";
import toyota from "../../../assets/images/landing/partners/toyota.svg";
import Slider from 'react-slick';

function PartnerSlider(props) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: '',
        prevArrow: '',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]


    };
    return (
        <Slider {...settings} className='partnerslider'>
            <div className='partner'>
                <img src={amazone} alt="partner" />
            </div>
            <div className='partner'>
                <img src={apple} alt="partner" />
            </div>
            <div className='partner'>
                <img src={atandt} alt="partner" />
            </div>
            <div className='partner'>
                <img src={cocacola} alt="partner" />
            </div>
            <div className='partner'>
                <img src={google} alt="partner" />
            </div>
            <div className='partner'>
                <img src={intel} alt="partner" />
            </div>
            <div className='partner'>
                <img src={kfc} alt="partner" />
            </div>
            <div className='partner'>
                <img src={linkedin} alt="partner" />
            </div>
            <div className='partner'>
                <img src={mcdonald} alt="partner" />
            </div>
            <div className='partner'>
                <img src={microsoft} alt="partner" />
            </div>
            <div className='partner'>
                <img src={nike} alt="partner" />
            </div>
            <div className='partner'>
                <img src={pepsi} alt="partner" />
            </div>
            <div className='partner'>
                <img src={pg} alt="partner" />
            </div>
            <div className='partner'>
                <img src={samsung} alt="partner" />
            </div>
            <div className='partner'>
                <img src={toyota} alt="partner" />
            </div>
        </Slider>

    );
}

export default PartnerSlider;